@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap");

@tailwind base;
@tailwind utilities;
@tailwind components;

html {
  /* @apply font-roboto;  */
  @apply font-inter;
}

@layer base {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
}

@layer utilities {
}

@layer components {
  /* .text-input-animation > input {
    color: transparent;
  }


*/
  .text-input-animation > input:focus {
    @apply text-black;
  }

  .text-input-animation > div.not-focused {
    @apply text-xs text-gray-500;
    transition: all 0.1s ease-in-out;
    top: 3px;
  }

  .text-input-animation > div.is-focused {
    @apply text-xs text-gray-500;
    transition: all 0.1s ease-in-out;
    top: 3px;
  }
}
