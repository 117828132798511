/* @import '@radix-ui/colors/black-alpha.css'; */

/* reset */
/* button {
  all: unset;
} */

.SwitchRoot {
  width: 36px;
  height: 20px;
  background-color: #ddd;
  border-radius: 9999px;
  position: relative;
  /* box-shadow: 0 2px 10px #000; */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/* .SwitchRoot:focus {
  box-shadow: 0 0 0 2px #0d0;
} */
.SwitchRoot[data-state='checked'] {
  background-color: #222;
}

.SwitchThumb {
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 9999px;
  box-shadow: 0 2px 2px var(--black-a7);
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;
}
.SwitchThumb[data-state='checked'] {
  transform: translateX(19px);
}

/* .Label {
  color: white;
  font-size: 15px;
  line-height: 1;
} */